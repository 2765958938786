import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useAuth = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const handleUnauthorized = () => {
    localStorage.removeItem("token");
    delete api.defaults.headers.common["Authorization"];
    setIsAuth(false);
    setUser({});
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const refreshToken = async () => {
      try {
        const { data } = await api.post("/auth/refresh_token");
        localStorage.setItem("token", data.token);
        api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
        setIsAuth(true);
        setUser(data.user);
      } catch (err) {
        handleUnauthorized();
        toastError(err);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      refreshToken();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("user", (data) => {
      if (data.action === "update" && data.user.id === user.id) {
        setUser(data.user);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleLogin = async (userData) => {
    try {
      const { data } = await api.post("/auth/login", userData);
      localStorage.setItem("token", data.token);
      api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      setIsAuth(true);
      setUser(data.user);
      toast.success(i18n.t("auth.toasts.success"));
      history.push("/tickets");
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await api.delete("/auth/logout");
      handleUnauthorized();
      localStorage.removeItem("token");
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  return { isAuth, user, loading, handleLogin, handleLogout };
};

export default useAuth;

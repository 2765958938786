import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const filter = createFilterOptions({
	trim: true,
});

const FilterUser = ({ modalOpen, onClose, users, onSelectUserIdChange }) => {
	const history = useHistory();

	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (!modalOpen || selectedContact) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts");
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [modalOpen, selectedContact]);

	const handleClose = () => {
		onClose();
		setSelectedContact(null);
	};

	const handleSaveFilter = () => {
        if (!selectedContact) return;
    const selectedUserId = selectedContact;
    const selectedUserName = users.find((user) => user.id === selectedContact)?.name;
    onSelectUserIdChange(selectedUserId); 
    handleClose();
        
    };
	
    const handleSelectOption = (event) => {
        setSelectedContact(event.target.value);
    };
    const showAll = () => {
        onSelectUserIdChange(0);
        handleClose();
    }
	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={() => setContactModalOpen(false)}
				onSave={(contact) => {
					setNewContact(contact);
					setContactModalOpen(true);
				}}
			/>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">Filtrar Usuário</DialogTitle>
            
				<DialogContent dividers>
                    
					<Select
						value={selectedContact}
						onChange={handleSelectOption}
						style={{ width: 300 }}
						displayEmpty
                      
					>
						<MenuItem value="Filtre Usúario" disabled>
								Filtre por Usuário
						</MenuItem>
						{users?.map((option) => (
                           <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
						))}
					</Select>
				</DialogContent>
				<DialogActions>
                <Button
						onClick={showAll}
						color={selectedContact ? "default" : "primary"}
						disabled={loading}
						variant="outlined"
                        style={
                            {
                                width: "50%",
                                marginLeft: "5%",
                            }
                        }

					>
					Mostrar Todos
					</Button>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
               
					<ButtonWithSpinner
						variant="contained"
						type="button"
						onClick={handleSaveFilter}
						color="primary"
						loading={loading}
					>
						Filtrar
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default FilterUser;

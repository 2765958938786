import { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_WHATSAPPS":
      return [...action.payload];

    case "UPDATE_WHATSAPP":
      const updatedWhatsApp = action.payload;
      const updatedIndex = state.findIndex((s) => s?.id === updatedWhatsApp?.id);
      return updatedIndex !== -1
        ? [...state.slice(0, updatedIndex), updatedWhatsApp, ...state.slice(updatedIndex + 1)]
        : [updatedWhatsApp, ...state];

    case "UPDATE_SESSION":
      const updatedSession = action.payload;
      const sessionIndex = state.findIndex((s) => s?.id === updatedSession.id);
      return sessionIndex !== -1
        ? [
            ...state.slice(0, sessionIndex),
            { ...state[sessionIndex], ...updatedSession },
            ...state.slice(sessionIndex + 1),
          ]
        : [...state];

    case "DELETE_WHATSAPP":
      const deletedWhatsAppId = action.payload;
      const deletedIndex = state.findIndex((s) => s?.id === deletedWhatsAppId);
      return deletedIndex !== -1
        ? [...state.slice(0, deletedIndex), ...state.slice(deletedIndex + 1)]
        : [...state];

    case "RESET":
      return [];

    default:
      return state;
  }
};

const useWhatsApps = () => {
  const [whatsApps, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(true);

  const handleSocketData = (data) => {
    try {
      switch (data.action) {
        case "update":
          dispatch({ type: "UPDATE_WHATSAPP", payload: data.whatsapp });
          break;

        case "delete":
          dispatch({ type: "DELETE_WHATSAPP", payload: data.whatsappId });
          break;

        case "updateSession":
          dispatch({ type: "UPDATE_SESSION", payload: data.session });
          break;

        default:
          break;
      }
    } catch (error) {
      console.error("Error handling socket data:", error);
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchSession = async () => {
      try {
        const { data } = await api.get("/whatsapp");
        dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    };

    fetchSession();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("whatsapp", handleSocketData);
    socket.on("whatsappSession", handleSocketData);

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return { whatsApps, loading };
};

export default useWhatsApps;

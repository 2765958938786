import { useCallback, useState } from "react";
import api from "../../services/api";

const useQueues = () => {
  const [loading, setLoading] = useState(false);

  const fetchQueues = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/queue");
      return data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return { fetchQueues, loading };
};

export default useQueues;
